/**
 * Control de la información de configuración del usuario
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export default function ConfigReducer(state, action) {
  switch (action.type) {
    //Insertamos la configuración del usuario
    case "CONFIG":
      return action.payload
    default:
      return state;
  }
}

/**
 * Añandimos un nuevo mensaje que luego se presentara al usuario.
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export default function GeneralReducer(state, action) {
    switch (action.type) {
      //Identificamos al usuario y optenemos el token
      case "MESSAGE":     
        return {
          ...state,
          message:  action.payload.message
        };
      default:
        return state;
    }
  }
  
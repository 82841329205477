const API_URL = 'https://alcovisor.cdpsa.es/page_app.html'
/**
 * Llamada a la api del servidor para obtener la información
 * @param {*} post 
 * @returns 
 */
export default function getApi(post) {
    return fetch(API_URL, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(post)
    }).then(res => {
      if (!res.ok) return {message:'Response_error' ,type:'error',code:0}
      return res.json()
    }).then(res => {
      return res
    })
    .catch(err => {    
      return { message:err,type:'error',code:0}     
    })
  }
/*
Listado de los item, en este componente se trabaja con los listados de cada tabla
La url define la tabla que contiene la información de las columnas y los datos
*/
import { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from 'react-router-dom'
import { GlobalContext } from "context/GlobalContext";
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import "translations/i18n"
import { DataGrid, GridActionsCellItem, GridToolbar } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { Box } from "@mui/material";
import getApi from "service/getApi";
//const modules = {'/profile':'profile','/company':'company'}
const ListModule = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    let navigate = useNavigate();
    const NAME_MODULE = location.pathname.split("/")[1]
    const { state, removeRecord, message } = useContext(GlobalContext);
    const [columnKey, setColumnKey] = useState('')
    const [column, setColumn] = useState([])
    const [hideColumn, setHideColumn] = useState({})
    const [dialog, setDialog] = useState({ title: '', description: '', cancel: false, open: false })
    //Activamos este estado para controlar los elementos selecionados en la lista
    const [selectionRow, setselectionRow] = useState([]);
    // Cerramos la ventana de dialogo
    const handleClose = () => setDialog({ open: false });
    //El numero de registros por paginas
    const [pageSize, setPageSize] = useState(10);
    //Mostramos ventana de dialogo para la confirmación del borrado
    const deleteModal = useCallback(
        (id) => () => {
            setDialog({ title: t('Borrar_registro'), description: t('Seguro_que_quiere_borrar_registro'), cancel: true, open: true, acept:deleteItem(id) });
        },
        [NAME_MODULE],
    );
     //Borramos el registro una vez haya sido confirmado.
     //const handledeleteItemSelect =  () => {
       // if (selectionRow)
       // removeRecord(selectionRow, NAME_MODULE);
    //}
    const handleCheckboxItemSelect =  (newselectionRow) => {
        // if (selectionRow)
        // removeRecord(selectionRow, NAME_MODULE);
        setselectionRow(newselectionRow);
    }
    
    //Borramos el registro una vez haya sido confirmado.
    const deleteItem = useCallback(
        (id) => () => {
            handleClose()
            if (id.length > 0)
                removeRecord(id, NAME_MODULE);
            // setItemRemove(0)
        },
        [NAME_MODULE],
    );
    //Evento que hace que cambiemos a la ventana de edición del registro.
    const EditItem = useCallback(
        (id) => () => {
            navigate('view/' + id);
        },
        [NAME_MODULE],
    );
    //Evento que hace cunado pincha en un checkbox de la fila
    const handleClickCheckboxRow = useCallback(
        (newselectionRow) => () => {
            setselectionRow(newselectionRow)
        },
        [NAME_MODULE],
    );
    
    /*************
     * Function para obtener el codigo de desbloqueo.
     */
    const lockopen = useCallback(
        (device) => () => {
            const list = {
                fun: 'lockOpen', mod: '_app', sn: device, token: state.token
            }
            getApi(list)
                .then(result => {
                    if (result && (!result.code && result.code !== 0)) {
                        let description = [];
                        for (let idx in result) {
                            // description.push(<li key={idx}><span>{idx}</span>: {result[idx]}</li>);
                            description.push(<Box sx={{ borderBottom: 1, borderColor: '#e0e0e0', mt: '1rem', color: "#000", display: 'flex' }} key={idx} ><Box sx={{ fontWeight: 'bold', fontSize: 16, display: 'flex', justifyContent: 'center' }}>{t(idx)} </Box><Box sx={{ display: 'flex', justifyContent: 'center' }}> {result[idx]}</Box></Box>);
                        }
                        setDialog({ title: t('Desbloqueo_device'), description, cancel: false, open:true, acept: handleClose });
                    }
                    else {
                        message(result)
                    }
                })
                .catch(err => {
                    message({ message: err, type: 'error', code: 0 });
                })
        },
        [],
    );

    //Implementamos la definición de las columnas con sus excepciones etc...
    useEffect(function () {
        if (state.module[NAME_MODULE] && state.module[NAME_MODULE].config && state.module[NAME_MODULE].config.column && state.module[NAME_MODULE].record && Array.isArray(state.module[NAME_MODULE].record)) {
            //Columnas de la tabla
            var indiceborrar = 0
            const columnlist = state.module[NAME_MODULE].config.column.map((colum, key) => {
                if (colum.type === 'singleSelect') {
                    if (typeof colum.rel_table !== "undefined") {
                        if (typeof state.module !== "undefined" && typeof state.module[colum.rel_table] !== "undefined" && typeof state.module[colum.rel_table].record !== "undefined") {
                            if (props.col && props.col.indice && props.col.indiceValue) {
                                colum.valueOptions = SelectColumn({ record: state.module[colum.rel_table].record.filter(item => item[colum.indice] === colum.indiceValue), field: { key: colum.key, label: colum.label } })
                            }
                            else
                                colum.valueOptions = SelectColumn({ record: state.module[colum.rel_table].record, field: { key: colum.key, label: colum.label } })
                        }
                    }
                    if (colum.valueOptions)
                        colum.valueFormatter = ({ value }) => SelectLabel(value, colum.valueOptions)

                }
                if (colum.type === 'img') {
                    colum.renderCell = getImg
                }
                if (colum.field === 'GPSLA') {
                    colum.headerName = t("Label_Map")
                    colum.renderCell = getMapGPS
                }
                if (colum.field === 'GPSLO')
                    indiceborrar = key
                return colum;
            })
            if (indiceborrar > 0) {
                columnlist.splice(indiceborrar, 1);
            }
            //Las columnas ocultas
            var i;
            var hideColumns = {}
            for (i = 0; i < state.module[NAME_MODULE].config.column.length; i++) {
                if (state.module[NAME_MODULE].config.column[i].hide && state.module[NAME_MODULE].config.column[i].hide === true)
                    hideColumns[state.module[NAME_MODULE].config.column[i].id] = false;
            }
            //Mostramos los botones de opciones segun sus permisos
                const botones =
                {
                    field: 'actions',
                    type: 'actions',
                    headerName: t('Actions'),
                    getActions: (params) => {
                        const element = []
                        //Si es administrador.
                        if (state.config && state.config.permision && (state.config.permision.indexOf("ad_" + NAME_MODULE) !== -1 || state.config.permision.indexOf("all") !== -1))
                        {
                        element.push(
                            <GridActionsCellItem
                                icon={<EditIcon sx={{ color: "primary.dark" }} />}
                                label={t("Edit")}
                                onClick={EditItem(params.id)}
                            />,
                            <GridActionsCellItem
                                icon={<DeleteIcon sx={{ color: "red" }} />}
                                label={t("Delete")}
                                onClick={deleteModal([params.id])}
                            />
                        )
                        }
                        else
                        {
                            element.push(
                                <GridActionsCellItem
                                icon={<VisibilityIcon sx={{ color: "primary.dark" }} />}
                                label={t("view")}
                                onClick={EditItem(params.id)}
                            />
                            )
                        }
                        //Si tiene permisos de desbloqueo.
                        if (NAME_MODULE === 'device' && (state.config.permision.indexOf("lockOpen") !== -1 || state.config.permision.indexOf("all") !== -1))
                            element.push(
                                <GridActionsCellItem
                                    icon={<LockOpenIcon sx={{ color: "blue" }} />}
                                    label={t("LockOpen")}
                                    onClick={lockopen(params.row.handset_sn)}
                                />
                            )
                        return element;
                    }

                }
             
                columnlist.unshift(botones)
            setColumn(columnlist)
            setHideColumn(hideColumns)
            setColumnKey(state.module[NAME_MODULE].config.key)
        }
    }, [state.module])

    //Funcion que devuelve el array de elmento compuesto de un modulo con value y label, que es lo que acepta.
    function SelectColumn(lista) {
        const registro = lista.record.map((item) => (
            { value: item[lista.field.key], label: item[lista.field.label] }
        ))
        return registro
    }
    //Funcion que devulve el label de un valor, del array de elementos.
    function SelectLabel(value, valueOptions) {
        var i;
        for (i = 0; i < valueOptions.length; i++) {
            if (valueOptions[i].value === value)
                return valueOptions[i].label
        }
        return '';
    }
    //Obtenemos el enlace de google pon la posicion gps
    function getMapGPS(params) {
        
        return <a target="_blank" rel='noreferrer' href={"https://www.google.com/maps/place/" + converPositionGoogle(params.row.GPSLA) + ",%20" + converPositionGoogle(params.row.GPSLO)}>{t("View_Map")}</a>;;
    }
    //Funcion para implementar el grado en la url de google
    function converPositionGoogle(Data)
    {
        if (typeof Data !== "undefined")
        {
            const position= Data.indexOf('.')-2;
            Data =  (Data).slice(0, position) + "º" + (Data).slice(position);
        }
            return Data;
    }
    //Funcion para obtener una img de la url de la imagen.
    function getImg(params) {
        if (params.value)
            return <img src={"https://alcovisor.cdpsa.es/files/min" + params.value} alt={t("campura_img")} />
        else
            return ''
    }
    return (
        (column.length > 0 && state.module && state.module[NAME_MODULE] && state.module[NAME_MODULE].record && state.module[NAME_MODULE].config.column && columnKey) ?
            <div style={{ height: 770, width: '100%' }}>
                <Dialog
                    open={(dialog.open) ? true : false}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {dialog.title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {dialog.description}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {(dialog.cancel) ?
                            <Button onClick={handleClose}>{t("Cancel")}</Button> :
                            ""
                        }
                        <Button onClick={dialog.acept}>{t("Accept")}</Button>
                    </DialogActions>
                </Dialog>

               
                    <Button variant="contained"  color="primary" onClick={deleteModal(selectionRow)} startIcon={<DeleteIcon />} sx={{ "&:hover": { color: "#ffffff" }, mb: 3, mt: 3 }} disabled={selectionRow.length > 0 ?false:true } >
                    {t('Delete_' + NAME_MODULE)}
                  </Button>
      
                <DataGrid style={{ height: 686 }}
                    rows={state.module[NAME_MODULE].record}
                    columns={column}
                    pageSize={pageSize}
                     onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[10,50,100]}
                   
                    columnVisibilityModel={hideColumn}
                    onColumnVisibilityModelChange={(newModel) =>
                        setHideColumn(newModel)
                    }
                    disableSelectionOnClick
                    
                    initialState={{ pinnedColumns: { right: ['actions'] } }}
                    checkboxSelection
                 
                    onSelectionModelChange={handleCheckboxItemSelect}
                    selectionRow={selectionRow}

                    components={{
                        Toolbar: GridToolbar,
                    }} />
            </div>
            :
            t("no_records")

    );
}
export default ListModule
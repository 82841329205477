import { createContext, useReducer } from "react";
import AppReducer from "context/AppReducer";
import getApi from "service/getApi";
import i18next from "i18next";
const initialState = { general: { offset: 0, limit: 20 }, module: { complet: [] }, moduleCall: [], token: window.sessionStorage.getItem('token') };
export const GlobalContext = createContext(initialState)
/**
 * Definimos el glogal context donde se definen las funciones que gestionan los contenidos y avisan a los reducer para que los gestionen.
 * @param {*} param0 
 * @returns 
 */
export const ContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);
    //Gestionamos los mensajes que han surgido
    const message = (Message) => {
        //Si es 100001 se ha cerrado la sesión
        if (Message.code && Message.code === '10001')
            logOut()

        dispatch({
            type: "MESSAGE",
            payload: Message,
        });

    }
    //Identificamos al usuario
    const logIn = (idenfification) => {
        if (idenfification)
        dispatch({
            type: "REMOVE_DATA",
            payload: [],
        });
            dispatch({
                type: "LOGIN",
                payload: idenfification,
            });

    }
    //Añadimos la configuración del usuario.
    const configUser = (idenfification) => {
        if (idenfification) {
            if (typeof idenfification.user !== "undefined" && typeof idenfification.user.len !== "undefined" && idenfification.user.len !== '')
                i18next.changeLanguage(idenfification.user.len);
            dispatch({
                type: "CONFIG",
                payload: idenfification,
            });
        }

    }
    //Cerramos sessión del usuario.
    const logOut = () => {
        window.sessionStorage.removeItem('token')
        dispatch({
            type: "LOGOUT",
            payload: { ...state, token: null },
        });
    }
    //Obtenemos los registros de una tabla
    const getRecords = (nameModule, parameter = {}) => {
        if (!state.module.complet.includes(nameModule)) {
            dispatch({
                type: "ADD_MODULE",
                payload: nameModule,
            });


            const list = {
                ...parameter,
                fun: 'read', mod: '_app', offset:
                    state.offset, limit: state.limit, token: state.token, module: nameModule
            }
            getApi(list)
                .then(result => {
                    if (result && typeof result.code === "undefined") {
                        listRecord({ name: nameModule, result });
                    }
                    else {
                        message(result)
                    }
                })
                .catch(err => {
                    message({ message: err, type: 'error', code: 0 });
                })

        }

    }
    //Añadimos o modificamos un registro.
    function addRecord(record, module) {
        const list = {
            fun: 'add', mod: '_app', record, module, token: state.token
        }
        getApi(list)
            .then(result => {
                if (result && (!result.code && result.code !== 0)) {
                    if (record.id) {
                        dispatch({
                            type: "UPDATE_RECORD",
                            payload: { result, name: module },
                        });
                    }
                    else {
                        dispatch({
                            type: "ADD_RECORD",
                            payload: { result, name: module },
                        });
                    }
                }
                else {
                    message(result)
                }
            })
            .catch(err => {
                message({ message: err, type: 'error', code: 0 });
            })
    }
    //Añadimos o modificamos un registro.
    function removeRecord(itemremove, module) {
        const list = {
            fun: 'remove', mod: '_app', module, itemremove, field: state.module[module].config.key, token: state.token
        }
        getApi(list)
            .then(result => {
                if (result && (!result.code && result.code !== 0)) {
                    dispatch({
                        type: "REMOVE_RECORD",
                        payload: { itemremove, module },
                    });
                }
                else {
                    message(result)
                }
            })
            .catch(err => {
                message({ message: err, type: 'error', code: 0 });
            })
    }
    //Añadimos una lista de registros para un componente
    const listRecord = (record) => {
        dispatch({
            type: "ADD_LIST",
            payload: record,
        });
    }
    //Añadimos una lista de columnas para un componente
    const listColumn = (column) => {
        dispatch({
            type: "COLUMN",
            payload: column,
        });
    }
    //Añadimos un modulo si no exite.
    const putMolule = (module) => {
        dispatch({
            type: "PUTMODULE",
            payload: module,
        });
    }
    return <GlobalContext.Provider value={{
        state,
        putMolule,
        addRecord,
        removeRecord,
        getRecords,
        logIn,
        logOut,
        message,
        listRecord,
        listColumn,
        configUser
    }}>
        {children}
    </GlobalContext.Provider>
}
import { useNavigate, useLocation,Link } from "react-router-dom";
import { useContext, useState, useEffect } from "react";
import { GlobalContext } from "context/GlobalContext";
import getApi from "service/getApi";
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Icon from '@mui/material/Icon';
import Grid from '@mui/material/Grid';
import logo from 'images/logo-cdp.png'; // with import
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Person from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";
import "translations/i18n"

/**
 * Pagina para solicitar la identificación.
 * @returns 
 */
const Login = () => {
    const { t } = useTranslation();
    const [values, setValues] = useState({
        password: "",
        showPassword: false,
    });
    //Funcion para hacer visible la password en el input
    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    let location = useLocation();
    let navigate = useNavigate();
    let from = location.state?.from?.pathname || "/";
    const { message, logIn, state, configUser } = useContext(GlobalContext);
    const [person, setPerson] = useState({ username: "", password: "", condiciones: false, fun: 'get', mod: '_user' });
    //Registramos los cambios en los campos
    const handleChange = (e) => {

        if (e.target.type === "checkbox")
            setPerson({ ...person, [e.target.name]: !person[e.target.name] });
        else
            setPerson({ ...person, [e.target.name]: e.target.value });
    }
    //Enviamos el formulario para la verificación del usuario y obtención del token.
    const handleSubmit = (e) => {
        e.preventDefault();
        if (person.username !== '' && person.password !== '' && person.condiciones) {
            //Llamamos al servidor para recibir el token
            getApi(person)
                .then(result => {
                    //Si recibimos el token
                    if (result && result.data && result.data.token) {
                        const token = result.data.token
                        window.sessionStorage.setItem('token', token)
                        logIn(token);
                        configUser({ 'loading': false });
                    }
                    else {
                        message(result)
                    }
                })
                .catch(err => {
                    message({ message: err, type: 'error', code: 0 });
                })

        } else {
            if (person.username === '')
                message({ message: t("username_required"), type: 'warning', code: 0 })
            else
                if (person.password !== '')
                    message({ message: t("username_condiciones"), type: 'warning', code: 0 })
                else
                    message({ message: t("username_password"), type: 'warning', code: 0 })
        }
    }
    //Si la autenticación es correcta redireccionamos al usuario a la url que puso.
    useEffect(() => {
        if (state.token) {
            navigate(from, { replace: true });
        }
    }, [state.token, from, navigate])
    //Estructura de contenido html
    return (
        <Grid direction="column" justifyContent="center" container style={{ minHeight: "100vh" }}>
            <form className="user" onSubmit={handleSubmit}>
                <Grid container item xs={10} sm={4} alignItems="center" direction="column" justify="space-between" style={{ padding: 10, margin: 'auto', borderRadius: 10, border: "1px solid grey" }}>

                    <Grid item style={{ padding: 20 }}>
                        <img src={logo} alt="logo" />
                    </Grid>

                    <Grid item container alignItems="center" justify="center" rowSpacing={2}>

                        <FormControl sx={{ m: 2, width: '100%' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-user">{t('User')}</InputLabel>

                            <OutlinedInput
                                id="outlined-adornment-user"
                                type='text'
                                value={person.username}
                                name="username"
                                onChange={handleChange}
                                autoFocus
                                endAdornment={<InputAdornment position="end">
                                    <Icon
                                        aria-label="toggle password visibility"
                                        edge="end">
                                        <Person />
                                    </Icon>
                                </InputAdornment>}
                                label={t('User')}
                            />
                        </FormControl>

                        <FormControl sx={{ m: 2, width: '100%' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">{t('password')}</InputLabel>

                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={values.showPassword ? 'text' : 'password'}
                                value={person.password}
                                name="password"
                                onChange={handleChange}
                                autoFocus
                                endAdornment={
                                    <InputAdornment position="end">
                                        <Icon
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                        >
                                            {values.showPassword ? <Visibility /> : <VisibilityOff />}
                                        </Icon>
                                    </InputAdornment>
                                }
                                label={t('password')}
                            />
                        </FormControl>

                        <FormGroup sx={{ m: 2, width: '100%' }}>
                            <FormControlLabel control={<Checkbox value={1} id="condiciones" checked={(person.condiciones)} onChange={handleChange} />} label={<a rel="noopener noreferrer" href={t('linkCondiciones')}  target="_blank">{t('Acepto_las_condiciones')}</a>} name="condiciones" />

                            
                        </FormGroup>
                    </Grid>

                    <Grid item alignItems="center" justify="center" style={{ padding: 20 }}>
                        <Button type="submit" variant="contained" color="primary">
                            {t('Log_in')}
                        </Button>
                    </Grid>


                </Grid>
            </form>
        </Grid>
    )
}
export default Login
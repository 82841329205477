/**
 * Componente que aparecera en la cabecera de las paginas, en la parte superior, aparece usuario y poder cerrar sesión.
 */
import { useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import PersonIcon from '@mui/icons-material/Person';
import LogoutIcon from '@mui/icons-material/Logout';
/***
 * Cabecera general de la aplicación, mostramos user y logout para que pueda salir.
 */
export default function AppHeader(props) {
    const { state, logOut } = useContext(GlobalContext);
    const handleClick = () => logOut()
    return (
        <Toolbar sx={{ justifyContent: "flex-end" }}>
            <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={props.handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' }, color: "#000000", marginRight: 'auto' }}
            >
                <MenuIcon />
            </IconButton>
            <IconButton>
                <PersonIcon />
                <Typography noWrap component="div">
                    {(state.config && state.config.user)?state.config.user.name:'anonimo'}
                </Typography>
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton onClick={handleClick}>
                <LogoutIcon />
            </IconButton>
        </Toolbar>
    );
}
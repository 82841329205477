/**
 * Componente para los desplegables, puede ser multiple o simple.
 */
import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "context/GlobalContext";
import { MenuItem } from '@mui/material';
import { InputLabel } from '@mui/material';
import { Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import "translations/i18n"
import getJson from "service/util";
const SelectCustom = (props) => {
    const { state, getRecords } = useContext(GlobalContext);
    const [lista, setLista] = useState({})

    //Inicialmente cargamos los registros si no estan cargados.
    useEffect(function () {
        //Llamamos al servidor para mandar el token 
        if (typeof props.col.rel_table !== "undefined" && typeof props.col.label !== "undefined" && typeof props.col.key !== "undefined") {
            if (!(typeof state.module !== 'undefined' && typeof state.module[props.col.rel_table] !== 'undefined' && typeof state.module[props.col.rel_table].record !== 'undefined')) {
                getRecords(props.col.rel_table)
            }
        }
    }, [])// eslint-disable-line react-hooks/exhaustive-deps
    //Una vez se haya cargado los datos del servidor los implmentamos aquí
    useEffect(function () {
        //Llamamos al servidor para mandar el token 
        if (typeof props.col.rel_table !== "undefined") {

            if (typeof lista.record === "undefined" && typeof state.module !== "undefined" && typeof state.module[props.col.rel_table] !== "undefined" && typeof state.module[props.col.rel_table].record !== "undefined") {
                //Si es una taxonomia filtra el contenido.
                if (props.col && props.col.indice && props.col.indiceValue) {
                    setLista({
                        ...lista, record: state.module[props.col.rel_table].record.filter(colum =>
                            (colum[props.col.indice] === props.col.indiceValue)
                        ), field: { key: props.col.key, label: props.col.label }
                    })
                }
                else
                    setLista({ ...lista, record: state.module[props.col.rel_table].record, field: { key: props.col.key, label: props.col.label } })
            }
        }
    }, [state.module, lista])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        (typeof lista !== "undefined" && typeof lista.record !== "undefined" && typeof lista.field !== "undefined") ?
            <FormControl className="campoFormulario">
                <InputLabel id={props.col.id + '_label'} sx={{ backgroundColor: 'white', pl: 1, pr: 1 }}>{props.name}</InputLabel>
                <Select
                    labelId={props.col.id + '_label'}
                    id={props.col.id}
                    name={props.col.id}
                    multiple={props.col.type === "multiselect" ? true : false}
                    value={(props.col.type === "multiselect" && (typeof props.value === 'string' || props.value === null)) ?
                        getJson(props.value)
                        : (props.col.type === "multiselect" && typeof props.value === 'undefined') ? [] : (props.value) ? props.value : (props.col.defaultvalue)?props.col.defaultvalue:''}
                    onChange={props.handleChange}
                >
                    {lista.record.map((item) => (
                        <MenuItem
                            key={item[lista.field.key]}
                            value={item[lista.field.key]}
                        >
                            {item[lista.field.label]}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            : ''
    )
}
export default SelectCustom
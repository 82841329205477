/**
 * Componente para informar al usuario con mensajes.
 */
import { useContext, useState, useEffect } from "react";
import { GlobalContext } from "context/GlobalContext"
import { t } from "i18next";
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
const Message = () => {
    const [open, setOpen] = useState(false);
    const { state, message } = useContext(GlobalContext);
    const handleClose = () => {
        message('');
        setOpen(false);
    }
    useEffect(function () {
        setOpen(true);
    }, [state.general.message])
    return (
        ((state.general && state.general.message) ?
            <div>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t(state.general.message)}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} autoFocus>
                            {t('Close')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div> : null)
    )
}

export default Message



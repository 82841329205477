/**
 * Obtenemos el json para que no den problemas los select.
 * @param {*} str 
 * @returns 
 */
function getJson(str) {

    try {
        str= JSON.parse(str);
    } catch (e) {
        str= str.split('@@@');
    }
    if (typeof str ==="number")
        str= [str]
    if (str ===null)
        str= []
    return str;
}
export default getJson
import GeneralReducer from "./fragment/GeneralReducer";
import LoginReducer from "./fragment/LoginReducer";
import DataReducer from "./fragment/DataReducer";
import ConfigReducer from "./fragment/ConfigReducer";
/**
 * //Definimos las funcionalidades de las diferentes contextos
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export default function AppReducer(state, action) {
  return {
    general: GeneralReducer(state.general, action),
    token: LoginReducer(state.token, action),
    config: ConfigReducer(state.config, action),
    module: DataReducer(state.module, action),
  }
}

/*
Componente para el formulario, especifico para la fecha, con hora y minuto, utilizamos la libreria de mui
*/
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import DatePicker from '@mui/lab/DatePicker';



/*const maskMap = {
  es: 'yyyy-MMM-dd',
  en: 'yyyy-MMM-dd',
  ca: 'yyyy-MMM-dd',
};
*/
export default function DateTime(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} >
      {(props.col.type === 'datetime') ?
        <DateTimePicker
          mask={'____-__-__ __:__:__'}
          renderInput={(props) =>
            <TextField {...props} />}
          label={props.name}
          name={props.col.id}
          value={(typeof props.value !== 'undefined') ? props.value : (props.col.defaultvalue)?props.col.defaultvalue:''}
          onChange={(newValue) => props.handleChange({ name: props.col.id, value: newValue })}
          className={"form-control " + props.class}
          inputFormat="yyyy-MM-dd hh:mm:ss a"
          disabled={props.col.disabled ? props.col.disabled : false} required={props.col.required ? props.col.required : false} 
        /> :
        <DatePicker
          mask={'____-__-__'}
          renderInput={(props) =>
            <TextField {...props} />}
          label={props.name}
          name={props.col.id}
          value={(typeof props.value !== 'undefined') ? props.value : (props.col.defaultvalue)?props.col.defaultvalue:''}
          onChange={(newValue) => props.handleChange({ name: props.col.id, value: newValue })}
          className={"form-control " + props.class}
          inputFormat="yyyy-MM-dd"
          disabled={props.col.disabled ? props.col.disabled : false} required={props.col.required ? props.col.required : false} 
        />
      }
    </LocalizationProvider>
  );
}
/*
Componente para el formulario, determina el tipo de datos y crea el componente adecuado segun el tipo.
*/
import SelectCustom from "./SelectCustom"
import { Switch, TextField, FormControlLabel, FormControl } from "@mui/material"
import DateTime from './DateTime'

const ElementForm = (props) => {
    if (typeof props.col.type !== undefined)
        switch (props.col.type) {
            case 'textarea':
            case 'json':
                return <FormControl className="campoFormulario"><TextField multiline className={"form-control " + props.class} value={(typeof props.value !== 'undefined') ? props.value : (props.col.defaultvalue)?props.col.defaultvalue:''} id={props.col.id} name={props.col.id} placeholder={props.name} label={props.name} maxLength={props.size} onChange={props.handleChange} disabled={props.col.disabled ? props.col.disabled : false} required={props.col.required ? props.col.required : false}  style={{ width: '100%' }} /></FormControl>
            case 'select':
            case 'singleSelect':
            case 'multiselect':
            case 'multisingleSelect':
                return <SelectCustom {...props} />
            case 'longblob':
                return <Img {...props} />
            case 'datetime':
            case 'date':
                return <FormControl className="campoFormulario"><DateTime {...props} /></FormControl>
            case 'boolean':
                return <FormControl className="campoFormulario" required={true}><FormControlLabel control={<Switch className={"form-control " + props.class} value={1} id={props.col.id} name={props.col.id} onChange={props.handleChange} checked={(props.value > 0)} disabled={props.col.disabled ? props.col.disabled : false} />} label={props.name} /></FormControl>
            default:
                return <FormControl className="campoFormulario"><TextField inputProps={{
                    maxLength: props.col.size,
                    minLength: props.col.sizemin
                  }} className={"form-control " + props.class} value={(typeof props.value !== 'undefined') ? props.value : (props.col.defaultvalue)?props.col.defaultvalue:''} id={props.col.id} name={props.col.id} label={props.name} variant="outlined" type={props.col.type ? props.col.type!=='float' ? props.col.type : "number" : "text"} maxLength={props.size} onChange={props.handleChange} defaultChecked={props.defaultChecked} disabled={props.col.disabled ? props.col.disabled : false} step={props.col.type==='number' ? 1 : "any"} required={props.col.required ? props.col.required : false} 
                onInput = {(e) =>{
                    if (typeof props.col.size!== "undefined")
                        e.target.value = e.target.value.toString().slice(0,props.col.size);
                }}
                 /></FormControl>
        }
}
export default ElementForm

//Colnstrulle un elemento de tipo imagen.
const Img = (props) => {
    return (
        (props.value) ?
            <FormControl className="campoFormulario">
                <img src={props.value} alt={props.name} />
            </FormControl>
            :
            ''
    )
}
/**
 * Control de la autenticación mediante el token.
 */
import { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom'
import { GlobalContext } from 'context/GlobalContext';
import Spinner from './Spinner';
import getApi from "service/getApi";

export default function RequireAuth({ children }) {
  let location = useLocation();
  const { state, configUser, message,logIn } = useContext(GlobalContext);
  useEffect(() => {
    if (typeof state.token=== "undefined" || state.token===''|| state.token===null)
    {
      const token=window.sessionStorage.getItem('token')
      if (token)
      {
        logIn(token);
      }
    }
  },[])// eslint-disable-line react-hooks/exhaustive-deps

   useEffect(() => {
    if ((!state.config || !state.config.navigation) && !(typeof state.token=== "undefined" || state.token===''|| state.token===null)) {
      configUser({'loading':true});
      const person = {
        fun: 'config', mod: '_user', token: state.token
      }
      getApi(person)
        .then(result => {
          //Si recibimos el token
          if (result && result.data && result.data) {
            configUser(result.data);
          }
          else {
            //Si no
            message(result)
          }
        })
        .catch(err => {
          message({ message:err,type:'error',code:0});
        })
    }
}, [state.token,state.message])// eslint-disable-line react-hooks/exhaustive-deps
//Si no hay token es que no esta autenticado. 
    if (!state.token) {
      // Redirect them to the /login page, but save the current location they were
      // trying to go to when they were redirected. This allows us to send them
      // along to that page after they login, which is a nicer user experience
      // than dropping them off on the home page.
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  if (!state.config || ((typeof state.config.loading !== "undefined" && state.config.loading))) 
    return <Spinner />
  else
    return children;
}

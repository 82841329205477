/**
 * Control de los registros de las diferentes tablas, composición de los mudulos, tanto registros como columnas.
 * 
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export default function DataReducer(state, action) {
  switch (action.type) {
    //Añadilos la lista de registros
    case "ADD_LIST":
      return {
        ...state,
        [action.payload.name]: { ...state[action.payload.name], record: action.payload.result }
      };
    //Añadimos la lista de campos o columnas
    case "COLUMN":
      if (action.payload.result.column)
        action.payload.result.column = action.payload.result.column.map
          (function (column) {
            column.selector = row => row[column.id];
            return column;
          })
      return {
        ...state,
        [action.payload.name]: { ...state[action.payload.name], config: action.payload.result }
      };
    //Añadimos un registro
    case "ADD_RECORD":
      return {
        ...state,
        [action.payload.name]: { ...state[action.payload.name], record: state[action.payload.name].record.concat(action.payload.result) }

      };
    //Definimos el modulo activo
    case "PUTMODULE":
      return {
        ...state,
        active: action.payload
      };
    //Modificamos registro
    case "UPDATE_RECORD":
        const updated = (action.payload.result[0])?action.payload.result[0]:null;
        const updateds = state[action.payload.name].record.map((record) => {
          if (record[state[action.payload.name].config.key] === updated[state[action.payload.name].config.key])
            return updated;
          else
            return record;
        });
        return {
          ...state,
          [action.payload.name]: { ...state[action.payload.name], record: updateds }
        }
    //Borramos registro
    case "REMOVE_RECORD":
      return {
        ...state,
        [action.payload.module]: { ...state[action.payload.module], record: state[action.payload.module].record.filter((record) => !action.payload.itemremove.includes(record.id))}//record.id !== action.payload.itemremove)}
      };
      //Añadimos el nuevo registro
    case "ADD_MODULE":
      return {
        ...state,
        complet: [...state.complet, action.payload ]

      };
      //Borramos registro
    case "REMOVE_DATA":
      return {complet: []}
    default:
      return state;
  }
}
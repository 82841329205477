/**
 * En este componente definimos las rutas y componentes principales para las rutas
 */
import React, { lazy, Suspense, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { GlobalContext } from "context/GlobalContext";
import AppSidebar from "component/general/AppSidebar";
import AppHeader from "component/general/AppHeader";
import AppFooter from "component/general/AppFooter";
import Spinner from "component/Spinner";
import RequireAuth from 'component/RequireAuth';
import { Home } from "pages/Home";
import Message from 'component/Message';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import FormModule from 'component/Form/FormModule';
import ListModule from 'component/ListModule';
const drawerWidth = 285;
const Module = lazy(() => import('pages/Module'));
//const ListModule = lazy(() => import('component/ListModule'));
//const FormModule = lazy(() => import('component/Form/FormModule'));
const NotFoundPage = lazy(() => import('pages/NotFoundPage'));
const AppContent = () => {
  const { state } = useContext(GlobalContext);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Suspense fallback={<Spinner />}>
      <RequireAuth>
        <Box sx={{ display: 'flex' }}>
          <AppBar
            position="fixed"
            sx={{
              display: 'flex',
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { sm: `${drawerWidth}px` },
              backgroundColor: '#ffffff'
            }}
          >
            <AppHeader handleDrawerToggle={handleDrawerToggle} />
          </AppBar>
          < Box
            component="nav"
            sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
            aria-label="mailbox folders"
          >
            <AppSidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
          </Box>
          <Box
            component="main"
            sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
          >
            <Message></Message>
            <Toolbar />
            <Routes>
              <Route path="/" element={<Home />} />
              {(state.config && state.config.navigation) ? state.config.navigation.map((page) => (
                <Route key={page.url} path={page.url} element={<Module />} >
                  <Route path="" element={<ListModule />} />
                  <Route path="add" element={<FormModule />} />
                  <Route path="view/:id" element={<FormModule />} />
                </Route>
              )) : ''}
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
            <AppFooter />
          </Box>
        </Box>
      </RequireAuth>
    </Suspense>
  )
}

export default AppContent

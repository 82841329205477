import { Typography } from "@mui/material";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import DeviceImg from 'images/device.png';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import "translations/i18n"
/**
 * Pagina inicial una vez que esta identidicado el usuario.
 * @returns 
 */
export const Home = () => {
    const theme = createTheme();

    theme.typography.h2 = {
        fontSize: '3.75rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '2.6rem',
        },
    };
    const { t } = useTranslation();
    return (
        // <div className="container-fluid">
        //     <h1 className="h3 mb-2 text-gray-800">{t('Welcome')}</h1>
        // </div>
        <Box sx={{ flexGrow: 1 }}>
            <Grid container>
                <Grid item xs={12} sm={7} sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center' }}>
                    <Box p={2}>
                        <ThemeProvider theme={theme}>
                            <Typography variant="h2" component="div" gutterBottom>Alcovisor X8</Typography>
                            <Typography variant="h4" sx={{ color: 'black', mb: 1 }}>{t('Aprobado_segun_norma_EN_50436')}</Typography>
                            <Typography variant="h6">{t('Bloqueo_de_encendido_Interlock')}</Typography>
                        </ThemeProvider>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Box p={2} display="flex" alignItems="center" justifyContent="center">
                        <img src={DeviceImg} alt={t("CDPSA")} style={{ maxWidth: "100%" }} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}



/**
 * Se registra el token o su eliminación para activar o desactivar la autenticación.
 * @param {*} state 
 * @param {*} action 
 * @returns 
 */
export default function LoginReducer(state, action) {
  switch (action.type) {
    //Identificamos al usuario y optenemos el token
    case "LOGIN":
      return action.payload
      //Cerrramos sesión del usuario borrando el token
    case "LOGOUT":
      return null
    default:
      return state;
  }
}

/**
 * Componente que aparecera en el pie de las paginas
 */
import { t } from "i18next";
import {
    AppBar,
    Toolbar,
    Typography
} from "@mui/material/";
const AppFooter = () => {
    return (
        <AppBar position="static" elevation={0} component="footer" color="default">
            <Toolbar style={{ justifyContent: "center" }}>
                <Typography variant="caption">{t('Copyright')}</Typography>
            </Toolbar>
        </AppBar>
    )
}

export default AppFooter

import React, {  Suspense } from "react";
import { Route, Routes } from 'react-router-dom'
import Spinner from "component/Spinner";
import AppContent from 'component/general/AppContent';
import Login from 'pages/LoginPage';
import { ContextProvider } from 'context/GlobalContext';
import Message from 'component/Message';
import './app.min.css';
/**
 * 
 * Control de autentificación.
 */
function App() {
  return (
    <div className="App">
      <ContextProvider>
        <Suspense fallback={<Spinner />}>
          <Message />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" name="Home" element={<AppContent />} />
          </Routes>
        </Suspense>
      </ContextProvider>
    </div>
  );
}
export default App;


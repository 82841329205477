/**
 * Cargando....
 */
import { Component } from 'react'
import { LinearProgress, Box } from '@mui/material';

export class Spinner extends Component {
  render() {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    )
  }
}

export default Spinner

/**
 * Formulario con los campos del formulario
 */
import React, { useState, useContext, useEffect, Fragment } from "react";
import { GlobalContext } from "context/GlobalContext";
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import Spinner from "../Spinner";
import ElementForm from "./ElementForm";
import { useTranslation } from "react-i18next";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { Typography } from "@mui/material";
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

/***
 * se compone el formulario con los datos que haya.
 */
const FormModule = (props) => {
    const { t } = useTranslation();
    const location = useLocation();

    let navigate = useNavigate();
    const params = useParams();
    const NAME_MODULE = location.pathname.split("/")[1]
    const { state, addRecord,message } = useContext(GlobalContext);
    const [column, setColumn] = useState([])
    const [record, setRecord] = useState([])
    const [tab, setTab] = useState(0);
    //Para cambiar el tab y que se vea un contendio u otro
    const handleChangeTab = (event, newValue) => {
        setTab(newValue);
    };
    //Recogemos los datos de los cambios en los campos.
    const handleChange = e => {
        if (typeof e.target ==='undefined') {
            if (e.type === 'checkbox')
                setRecord({ ...record, [e.name]: (e.checked) ? 1 : 0 });
            else
            {
                if (typeof e.value==="object")
                {
                    let date = new Date( Date.parse(e.value) );
                    e.value=date.getFullYear() +'-' + (date.getMonth() + 1) + '-' + date.getDate() +' ' + date.getHours() +':' + date.getMinutes() +':' + date.getSeconds();
                    //e.value=date.toISOString().slice(0, 19).replace("T", " ");
                }
                setRecord({ ...record, [e.name]: e.value });
            }
        }
        else {
            if (e.target.type === 'checkbox')
                setRecord({ ...record, [e.target.name]: (e.target.checked) ? 1 : 0 });
            else
            {
                if (typeof e.value==="object")
                {
                    let date = new Date( Date.parse(e.target.value) );
                    e.target.value=date.getFullYear() +'-' + (date.getMonth() + 1) + '-' + date.getDate() +' ' + date.getHours() +':' + date.getMinutes() +':' + date.getSeconds();
                    //e.target.value =date.toISOString().slice(0, 19).replace("T", " ");
                }
                setRecord({ ...record, [e.target.name]: e.target.value });
            }
        }
    }
    //Enviamos los cambios del registro al servidor
    const handleSubmit = e => {
        e.preventDefault();
        //setProfile({...company, [e.target.name]: e.target.value });
        let error= '';
        //Verificamos que el campo cumple con las limitaciones.
        for (let i = 0; i < state.module[NAME_MODULE].config.column.length; i++) {
            const colum = state.module[NAME_MODULE].config.column[i];
            if (typeof colum.sizemin !== 'undefined' && colum.sizemin>record[colum.field].length)
            {
                error+=t('campo_minimo') + t(colum.field) + t('size_campo_minimo') + colum.sizemin+'\n';

            }
            if ((typeof colum.required !== 'undefined' && record[colum.field].length<1) || (record['id']===0 && colum.field==='password' && record['password'].length<1))
            {
                error+=t('campo_requered')+t(colum.field)+'\n';
            }
        }
        if (error!=='')
            message({ message: error, type: 'error', code: 0 });
        else
        {
            addRecord(record, NAME_MODULE)
            navigate('/' + NAME_MODULE);
        }
    }
    //Cuando cambiamos de url obtenemos los datos correspondiente, sirve como refresco si se cambia de url
    useEffect(function () {
        //Si el registro viene del listado desplegable.
        if ('add'===location.pathname.split("/")[2])
        {
            const idDefault='id';
            setRecord({ ...record, [state.module[NAME_MODULE].config.key]: 0,[idDefault]: 0 });
        }
        if (props.data) {
            setRecord(props.data)

        }
        else
            //Si el registro viene del registro existente.
            if (params.id && state.module[NAME_MODULE] && state.module[NAME_MODULE].record && state.module[NAME_MODULE].config && state.module[NAME_MODULE].config.key) {
                const data = state.module[NAME_MODULE].record.find((item) => item[state.module[NAME_MODULE].config.key] === params.id);
                if (data) {
                    setRecord(data)
                }
            }
    }, [location])// eslint-disable-line react-hooks/exhaustive-deps
    //Se activa si hay cambio en los modulos para definir las columnas y registros.
    useEffect(function () {

        if (state.module[NAME_MODULE] && state.module[NAME_MODULE].config && state.module[NAME_MODULE].config.column && state.module[NAME_MODULE].record && Array.isArray(state.module[NAME_MODULE].record)) {

            let group = '';
            let columnGroup = [];
            for (let i = 0; i < state.module[NAME_MODULE].config.column.length; i++) {
                const colum = state.module[NAME_MODULE].config.column[i]
                if (colum.group && colum.group !== group)
                    group = colum.group
                const name_colum = colum.name;
                colum.name = t(state.module.active + '_' + name_colum)
                if (colum.name === state.module.active + '_' + name_colum) {
                    colum.name = t(name_colum)
                }
                if (!columnGroup[group])
                    columnGroup[group] = [];
                columnGroup[group].push(colum)

            }
            setColumn(columnGroup)
        }
    }, [state.module[NAME_MODULE]])// eslint-disable-line react-hooks/exhaustive-deps

    return (
        (column && Object.keys(column).length) ?
            <form className="user" onSubmit={handleSubmit}>
                {(Object.keys(column).length === 1) ?
                    <Box
                        sx={{
                            '& .MuiTextField-root': { className: 'campoFormulario' },
                        }}
                        noValidate
                        autoComplete="off"
                    >
                        {column[Object.keys(column)[0]].map((col, index) =>
                            <Fragment key={col.field}>
                                {col.header && <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', m: 2, color: '#000', borderBottom: 1 }}>{t(col.header)}</Typography>}
                                {!col.hideForm && <ElementForm key={col.id} col={col} value={record[col.id]} name={t(col.name)} handleChange={handleChange} />}
                            </Fragment>
                        )}
                    </Box>
                    :
                    <TabContext value={tab}>
                        <Box
                            sx={{
                                '& .MuiTextField-root': { className: 'campoFormulario' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChangeTab} aria-label="menu"
                                    variant="scrollable">
                                    {Object.keys(column).map((group, index) =>
                                        <Tab label={t(group)} value={index} />
                                    )}
                                </TabList>
                            </Box>
                            {Object.keys(column).map((group, index) =>
                                <TabPanel value={index} index={index}>
                                    {column[group].map((col, index) =>
                                        <Fragment key={col.field}>
                                            {col.header && <Typography variant="h5" gutterBottom sx={{ m: 2, color: '#000', borderBottom: 1 }}>{t(col.header)}</Typography>}
                                            {!col.hideForm && <ElementForm key={col.id} col={col} value={record[col.id]} name={t(col.name)} handleChange={handleChange} />}
                                        </Fragment> 
                                    )}
                                </TabPanel>
                            )}
                        </Box>
                    </TabContext>
                }
                <Box sx={{ justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                    <Button variant="contained" type="submit" color="success" style={{ margin: 10 }}> {(typeof params.id !== 'undefined') ? t('Update') : t('Add')}</Button>
                </Box>

            </form>

            : <Spinner />
    )
}

export default FormModule;
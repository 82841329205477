/**
 * Componente que aparece en el lateral iquierdo para el menú de las opciones.
 */
import React, { useContext } from "react";
import { GlobalContext } from "context/GlobalContext";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "translations/i18n"
import logo from 'images/logo-cdp.png'; // with import
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Drawer from '@mui/material/Drawer';
import TapAndPlayIcon from '@mui/icons-material/TapAndPlay';
import FlagIcon from '@mui/icons-material/Flag';
import BadgeIcon from '@mui/icons-material/Badge';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AssignmentIcon from '@mui/icons-material/Assignment';
import BusAlertIcon from '@mui/icons-material/BusAlert';
import PersonIcon from '@mui/icons-material/Person';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DescriptionIcon from '@mui/icons-material/Description';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import RouterIcon from '@mui/icons-material/Router';
import SpatialTrackingIcon from '@mui/icons-material/SpatialTracking';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    components: {
        MuiListItem: {
            styleOverrides: {
                root: {
                    ".Mui-selected": {
                        color: "#1976d2"
                    },
                    ".Mui-selected .MuiListItemIcon-root": {
                        color: "#1976d2"
                    }
                },
            },
        },
    },
});

const AppSidebar = (props) => {
    const location = useLocation();
    const NAME_MODULE = location.pathname.split("/")[1]
    const { t } = useTranslation();
    const { state } = useContext(GlobalContext);
    const { window } = props;
    const container = window !== undefined ? () => window().document.body : undefined;

    const icon = {
        device: <TapAndPlayIcon />,
        company: <FlagIcon />,
        driver: <BadgeIcon />,
        vehicle: <LocalShippingIcon />,
        logeventsdata: <AssignmentIcon />,
        logviolation: <BusAlertIcon />,
        person: <PersonIcon />,
        profile: <AccountBoxIcon />,
        page: <DescriptionIcon />,
        taxonomy: <FormatListBulletedIcon />,
        driverDevice: <RouterIcon />,
        driverEnvironment: <SpatialTrackingIcon />,
    }

    const drawer = (
        <List>
            <ListItem>
                <ListItemIcon>
                <Link to=""><img src={logo} alt={t("CDPSA")} /></Link>
                </ListItemIcon>
            </ListItem>
            {(state.config && state.config.navigation) ? state.config.navigation.map((page) => (
                <ListItem disablePadding key={page.url} button component={Link} to={"/" + page.url} theme={theme}>
                    <ListItemButton selected={NAME_MODULE === page.url}>
                        <ListItemIcon>
                            {(icon[page.url]) ? icon[page.url] : icon.default}
                        </ListItemIcon>
                        <ListItemText primary={t(page.name)} />
                    </ListItemButton>
                </ListItem>
            )) : ''}
        </List>
    )
    return (
        <>
            <Drawer
                container={container}
                variant="temporary"
                open={props.mobileOpen}
                onClose={props.handleDrawerToggle}
                ModalProps={{
                    keepMounted: true, // Better open performance on mobile.
                }}
                sx={{
                    display: { xs: 'block', sm: 'none' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth },
                }}
            >
                {drawer}
            </Drawer>
            <Drawer
                variant="permanent"
                sx={{
                    display: { xs: 'none', sm: 'block' },
                    '& .MuiDrawer-paper': { boxSizing: 'border-box', width: props.drawerWidth },
                }}
                open
            >
                {drawer}
            </Drawer>
        </ >
    )
}

export default AppSidebar